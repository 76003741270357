import Config from "../../../config";
import Utils from '../../redux/utils';

const baseUri = Config.API_BASE_URI;

const getUserRoles = async () => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}userroles/getroles`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getCurrentUserRole = async (email) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}userroles/getuserrole/` + email,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const updateUsersRole = async (id, params) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpublishers/updaterole/` + id,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const addPropertyId = async (id, params) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpublishers/addpropertyid/` + id,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const addNetworkCode = async (id, params) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpublishers/addnetworkcode/` + id,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const deleteRegPublisher = async (id, params) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpublishers/deleteregpublisher/` + id,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'DELETE',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

export const addPropertyIdByEmail = async (email, propertyId) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpublishers/addpropertyid`, 
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
        'Content-Type': 'application/json' 
      },
      method: 'POST',
      data: {
        email: email,
        propertyid: propertyId
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    console.error('Error updating property ID:', error);
    throw error; 
  }
};



const updateAVUsersRole = async (email, params) => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpublishers/updateavrole/` + email,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const UserRoles = {
  getUserRoles,
  getCurrentUserRole,
  updateUsersRole,
  updateAVUsersRole,
  addPropertyId,
  addPropertyIdByEmail,
  addNetworkCode,
  deleteRegPublisher
};

export default UserRoles;
