import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { TheLayout } from './containers';
import './scss/style.scss';
import Protected from './Protected';
import Login from './views/pages/login/Login';

const loading = (
  <div className="pt-3 text-center">
  <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
 
const Register = React.lazy(() => import('./views/pages/register/Register'));
const ForgetPassword = React.lazy(() => import('./views/pages/forgetPassword/forgetPassword'));
const SetPassword = React.lazy(() => import('./views/pages/setPassword/setPassword'));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/forgetpassword" name="forget password" render={props => <ForgetPassword {...props}/>} />
              <Route exact path="/setpassword/*" name="set password" render={props => <SetPassword {...props}/>} />
              <Route path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route path="/integrations" name="Integrations"render={props => <TheLayout {...props}/>}/>
              <Route path="/dashboard" name="Dashboard">
              <Protected render={props => <TheLayout {...props}/>}/>               
              </Route> 
              <Route path="/" name="The Layout">
                <Protected render={props => <TheLayout {...props}/>}/>               
              </Route> 
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
