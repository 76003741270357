import React from 'react'
import { CFooter } from '@coreui/react'

const TheFooter = ({customClass}) => {
  return (
    <CFooter fixed={false} className={`d-flex justify-content-center ${customClass}`}>
      <div className="footer">
        <a href="https://advergic.com" target="_blank" rel="noopener noreferrer" className="footer-text">Advergic © 2024 | </a>
        <a href="https://advergic.com/privacy-policy/" target="_blank" rel="noreferrer noopener" className="footer-text"> Privacy Policy</a> – 
        <a href="https://advergic.com/terms-of-service/" target="_blank" rel="noreferrer noopener" className="footer-text"> Terms of Service</a>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)
