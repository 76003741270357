import Config from "../../../config";
import Utils from '../../redux/utils';

const baseUri = Config.API_BASE_URI;

const getDomains = async () => {
    try {
        const apiOptions = {
            endpoint: `${baseUri}domains/getdomains`,
            headers: {
                Authorization: `Bearer ${Utils.getCurrentToken()}`,
            },
            method: 'GET',
        };
        const apiResponse = await Utils.CallApi(apiOptions);

        return apiResponse.data;
    } catch (error) {
        return error;
    }
};

const getPubDomains = async (id) => {
    try {
        const apiOptions = {
            endpoint: `${baseUri}domains/getpubdomains/` + id,
            headers: {
                Authorization: `Bearer ${Utils.getCurrentToken()}`,
            },
            method: 'GET',
        };
        const apiResponse = await Utils.CallApi(apiOptions);

        return apiResponse.data;
    } catch (error) {
        return error;
    }
};

const getUserDomains = async (id) => {
    try {
        const apiOptions = {
            endpoint: `${baseUri}domains/getuserdomains/` + id,
            headers: {
                Authorization: `Bearer ${Utils.getCurrentToken()}`,
            },
            method: 'GET',
        };
        const apiResponse = await Utils.CallApi(apiOptions);

        return apiResponse.data;
    } catch (error) {
        return error;
    }
};


const Domains = {
    getDomains,
    getPubDomains,
    getUserDomains
};

export default Domains;