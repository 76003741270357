import Config from "../../../config";
import Utils from '../../redux/utils';

const baseUri = Config.API_BASE_URI;

const UserLogin = (payload) => {
   
  return new Promise(async (resolve, reject) => {
    try {
      const { email, password } = payload;
      const apiOptions = {
        endpoint: `${baseUri}regpublishers/loginpub`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
          email,
          password,
        },
      };
      const apiResponse = await Utils.CallApi(apiOptions);

      if (apiResponse.data?.authStatus) {
        Utils.setAuthStatus(apiResponse.data.authStatus);
      }
      
      resolve(apiResponse.data);
    } catch (error) {
      reject(error);
    }
  });
};

const UserAuthLogin = (payload) => {
   
  return new Promise(async (resolve, reject) => {
    try {
      const apiOptions = {
        endpoint: `${baseUri}/api/custom_login`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        data: payload,
      };
      const apiResponse = await Utils.CallApi(apiOptions);
      resolve(apiResponse.data);
    } catch (error) {
      reject(error);
    }
  });
};

const UserSignUp = (payload) => {
   
  return new Promise(async (resolve, reject) => {
    try {
      const { username, networkcode, email, password, passwordrepeat } = payload;
      const apiOptions = {
        endpoint: `${baseUri}regpublishers/registerpubs`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
          username: username,
          networkcode: networkcode,
          email: email,
          password: password,
          passwordrepeat: passwordrepeat,
        },
      };
      const apiResponse = await Utils.CallApi(apiOptions);
      resolve(apiResponse.data);
    } catch (error) {
      reject(error);
    }
  });
};

const UserLogout = async () => {
  try {
    const apiOptions = {
      endpoint: `${baseUri}regpublishers/exit`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const ForgotPassword = (payload) => {
   
  return new Promise(async (resolve, reject) => {
    try {
      const apiOptions = {
        endpoint: `${baseUri}management/recoverPubAccount`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
          email: payload,
        },
      };
      const apiResponse = await Utils.CallApi(apiOptions);
      resolve(apiResponse.data);
    } catch (error) {
      reject(error);
    }
  });
};

const ResetPassword = (password, token) => {
   
  return new Promise(async (resolve, reject) => {
    try {
      const apiOptions = {
        endpoint: `${baseUri}management/setPubPassword`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
          password: password,
          token: token,
        },
      };
      const apiResponse = await Utils.CallApi(apiOptions);
      resolve(apiResponse.data);
    } catch (error) {
      reject(error);
    }
  });
};

const VerifyEmail = (email) => {
   
  return new Promise(async (resolve, reject) => {
    try {
      const apiOptions = {
        endpoint: `${baseUri}/api/password/resendEmailSend`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
          email: email,
        },
      };
      const apiResponse = await Utils.CallApi(apiOptions);
      resolve(apiResponse.data);
    } catch (error) {
      reject(error);
    }
  });
};

const VerifyUser = (id, token, email, password) => {
   
  return new Promise(async (resolve, reject) => {
    try {
      const apiOptions = {
        endpoint: `${baseUri}/api/users_verify/${id}?token=${token}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        data: {
          email: email,
          password: password,
        },
      };
      const apiResponse = await Utils.CallApi(apiOptions);
      resolve(apiResponse.data);
    } catch (error) {
      reject(error);
    }
  });
};

const AuthActions = {
  UserLogin,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
  VerifyUser,
  UserSignUp,
  UserAuthLogin,
  UserLogout
};

export default AuthActions;
