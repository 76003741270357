import { configureStore, combineReducers } from '@reduxjs/toolkit';
import AuthReducer from './views/redux/reducers/authSlice';
import RecipeReducer from './views/redux/reducers/pubStatsSlice';

// Your existing reducer
const initialState = {
  sidebarShow: 'responsive',
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    default:
      return state;
  }
};

// Combine reducers
const rootReducer = combineReducers({
  Auth: AuthReducer,
  Recipe: RecipeReducer,
  UI: changeState, // Adding your sidebar state reducer
});

// Create the store
const store = configureStore({
  reducer: rootReducer,
});

export default store;