import React from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from "../assets/logo/Advergic-Logo.svg";
import { useSelector, useDispatch } from 'react-redux';
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CBreadcrumbRouter,
} from '@coreui/react';
import Utils from '../views/redux/utils';
import routes from '../routes';
import {
  TheHeaderDropdown
} from './index';
import DomainsDropdown from '../views/components/domainsDropdown/domainsDropdown'

const TheHeader = () => {
  const dispatch = useDispatch();
  const userrole = Utils.getCurrentUserRole();
  const pubemail = Utils.getCurrentUserEmail();
  const sidebarShow = useSelector(state => state.UI.sidebarShow);
  const location = useLocation();

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive';
    dispatch({ type: 'set', sidebarShow: val });
    
    const sidebarElement = document.querySelector('.c-sidebar'); 
    
    if (val === true) {
      sidebarElement.style.display = 'inherit';
    } else {
      sidebarElement.style.display = 'none';
    }
  
    const links = document.querySelectorAll('c-sidebar-nav-link c-active'); 
    links.forEach(link => {
      link.addEventListener('click', () => {
        if (val === true) {
          sidebarElement.style.display = 'none';
        } else {
          sidebarElement.style.display = 'none';
        }
      });
    });
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/dashboard">
        <Logo
            name="logo"
            height={25}
            alt ="Logo"/>
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to={location.pathname}> <CBreadcrumbRouter
            className="border-0 c-subheader-nav m-0 px-0 px-md-3"
            routes={routes}
          /></CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <CHeaderNav className="d-md-down-block mr-auto">
          {userrole && userrole?.length > 0 && userrole[0]?.roleid !== 3 ? (
            <CHeaderNavItem className="px-3" >
              <DomainsDropdown />
            </CHeaderNavItem>
          ) : null}
        </CHeaderNav>
        {pubemail && (<TheHeaderDropdown />)}
      </CHeaderNav>
    </CHeader>
  )
}

export default TheHeader
