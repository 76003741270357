/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
  CCardHeader,
  CRow,
  CCol,
  CContainer,
  CTabs,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
} from '@coreui/react';
import ga4image from '../../../assets/img/img/analytics.jpeg';
import opacc from '../../../assets/img/img/opacc.webp';
import apiacc from '../../../assets/img/img/apiacc.webp';
import ops1 from '../../../assets/img/img/ops1.webp';
import ops2 from '../../../assets/img/img/ops2.webp';
import ops3 from '../../../assets/img/img/ops3.webp';
import ops4 from '../../../assets/img/img/ops4.webp';
import ops5 from '../../../assets/img/img/ops5.webp';

// GA4SetupPage Component
const GA4SetupPage = () => (
  <CCard className="w-100 h-100 text-center shadow-lg mb-n1" style={{ maxWidth: '1200px', maxHeight: '800px' }}>
    <CCardHeader className="background_FF6634 text-white">
      <h2 className="font-weight-bold mb-0">Google Analytics 4 Setup</h2>
    </CCardHeader>
    <CCardBody className="d-flex flex-column justify-content-center">
      <img src={ga4image} className='mt-1 img-fluid mb-4' alt="GA4 Setup" />
      <CCardTitle className="h3 mt-4 font-weight-bold">Adding Advergic to Google Analytics Account</CCardTitle>
      <CRow className="text-left mt-3">
        <CCol xs="12">
          <CCardText className="mb-2">
            <strong>Step 1:</strong> Tap the <b>Settings</b> Icon.<br/>
            <strong>Step 2:</strong> Navigate to the <b>Admin</b> tab.<br/>
            <strong>Step 3:</strong> Access <b>Account Access Management</b> from the Admin panel.<br/>
            <strong>Step 4:</strong> Add <em>operations@advergic.com</em> as a viewer in <b>Account Access Management</b>.
          </CCardText>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
);

// CustomCarousel Component
const CustomCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const preloadImages = (images) => {
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  useEffect(() => {
    preloadImages(images);
  }, [images]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowRight') {
      handleNext(); 
    } else if (event.key === 'ArrowLeft') {
      handlePrev(); 
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); 
  
  return (
    <div className="custom-carousel">
      <div className="carousel-content">
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          style={{ width: '100%', height: '400px', objectFit: 'contain' }}
        />
      </div>
      <button onClick={handlePrev} className="carousel-control prev">&lt;</button>
      <button onClick={handleNext} className="carousel-control next">&gt;</button>
      <div className="carousel-indicators">
        {images.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

// GAMSetupPage Component
const GAMSetupPage = () => {
  const images = [opacc,ops1,ops2,ops3,apiacc,ops4,ops5];

  return (
    <CCard className="w-100 h-100 text-center shadow-lg mb-n1" style={{ maxWidth: '1200px'}}>
      <CCardHeader className="background_FF6634 text-white">
        <h2 className="font-weight-bold mb-0">Google Ad Manager Setup</h2>
      </CCardHeader>
      <CCardBody className="d-flex flex-column justify-content-center">
        <CustomCarousel images={images} />
        <CCardTitle className="h3 mt-4 font-weight-bold">Adding Advergic to Google Ad Manager Account</CCardTitle>
        <CRow className="text-left mt-3">
          <CCol xs="12">
            <CCardTitle className="h4 font-weight-bold">Operations Access</CCardTitle>
            <CCardText className="mb-2">
              <strong>Step 1:</strong> Login to your <b>GAM</b> Account.<br/>
              <strong>Step 2:</strong> Navigate to <b>Access & Authorization</b> under the <b>Admin</b> tab in the sidebar menu.<br/>
              <strong>Step 3:</strong> Click on the <b>New User</b> button under the <b>Users</b> section.<br/>
              <strong>Step 4:</strong> Enter the following details and save:
                <ul className="mt-2 mb-0">
                  <li><b>Name:</b> Advergic</li>
                  <li><b>Email:</b> operations@advergic.com</li>
                  <li><b>Confirm Email:</b> operations@advergic.com</li>
                  <li><b>Role:</b> Administrator</li>
                </ul>
            </CCardText>
            <CCardTitle className="h4 font-weight-bold">API Access</CCardTitle>
            <CCardText className="mb-2">
              <strong>Step 1:</strong> Login to your <b>GAM</b> Account.
            </CCardText>
            <CCardText className="mb-2">
              <strong>Step 2:</strong> Navigate to <b>Global Settings</b> under the <b>Admin</b> tab in the sidebar menu.
            </CCardText>
            <CCardText className="mb-2">
              <strong>Step 3:</strong> Under <b>Network settings</b> enable <b>API acces's</b> and Click on <b>Add a service account user</b> button.
            </CCardText>
            <CCardText className="mb-2">
              <strong>Step 4:</strong> Enter the following details and save:
              <ul className="mt-2 mb-0">
                <li><b>Name:</b> Advergic</li>
                <li><b>Email:</b> advergic-api-service@header-bidding-gam-406406.iam.gserviceaccount.com</li>
                <li><b>Confirm Email:</b> advergic-api-service@header-bidding-gam-406406.iam.gserviceaccount.com</li>
                <li><b>Role:</b> Administrator</li>
              </ul>
            </CCardText>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  );
};

// SetupPage Component
const SetupPage = () => {
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem('activeTab') || 'GA4';
  });

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  return (
    <>
    <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-center bg-white">
      <CContainer className="my-4">
        <CTabs activeTab={activeTab}>
          <CNav variant="pills">
            <CNavItem>
              <CNavLink
                data-tab="GA4"
                onClick={() => setActiveTab('GA4')}
                className={activeTab === 'GA4' ? 'active-tab' : 'text-muted'}
              >
                GA4 Setup
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink
                data-tab="GAM"
                onClick={() => setActiveTab('GAM')}
                className={activeTab === 'GAM' ? 'active-tab' : 'text-muted'}
              >
                GAM Setup
              </CNavLink>
            </CNavItem>
          </CNav>
          <CTabContent className="mt-2">
            <CTabPane data-tab="GA4">
              <GA4SetupPage />
            </CTabPane>
            <CTabPane data-tab="GAM">
              <GAMSetupPage />
            </CTabPane>
          </CTabContent>
        </CTabs>
      </CContainer>
    </div>            
    </>
  );
};

export default SetupPage;