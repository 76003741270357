import React from 'react'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index'
import Loader from '../views/components/Loader/Loader';
import { useSelector } from 'react-redux';

const TheLayout = () => {
  const isLoading = useSelector((state) => state.Auth.isLoading);
  
  return (
    <div className="c-app c-default-layout">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100 vw-100">
           <Loader />
        </div>
      ):(
      <>
      <TheSidebar/>
      <div className="c-wrapper">
        <div className='header_main'>
        <div className='header_container'>
        <TheHeader/>
        </div>
        </div>
        <div className="c-body">
          <TheContent/>
        </div>
        <TheFooter/>
      </div>
      </>
      )}
    </div>
  )
}

export default TheLayout
